/* eslint-disable camelcase */
export default class PostCategory {
  alias;
  name;
  description;
  library_id;

  constructor() {
    this.alias = '';
    this.name = '';
    this.description = '';
    this.library_id = '';
  }
}